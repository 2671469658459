<template>
<div>
  <div class="course-pad-t grey-bg">
      <div class="join-class-head">
          <div class="join-bg vertical-center">
              <div class="join-time">开营时间：{{ groupInfo.start_at != null ? $carbon.dateTransition(groupInfo.start_at ? groupInfo.start_at.substring(0,16):'') : '待开营'}} 添加班主任微信</div>
              <div class="join-tip">（请务必添加班主任为好友，否则影响上课）</div>
          </div>
          <div class="join-step-con">
              <div class="join-step">
                  <div class="step-title">
                      <span class="step-num">1</span>
                      <span class="step-text">请务必复制学号，用于微信好友验证</span>
                  </div>
                  <div class="stu-num-con">
                      <div class="stu-num">学号：{{groupInfo.number ? groupInfo.number : ''}}</div>
                      <div class="stu-num-copy" v-if="groupInfo.number != null" :data-clipboard-text="groupInfo.number" @click="copy">复制学号</div>
                  </div>
                  <div class="example">
                      <div class="example-tit">示例：</div>
                      <img class="example-img" src="../../../../assets/images/user/example.png" alt="">
                  </div>
              </div>
              <div class="step-two">
                  <div class="step-title">
                      <span class="step-num">2</span>
                      <span class="step-text">请务必复制学号，用于微信好友验证</span>
                  </div>
                  <div class="stop-tip">注：因微信验证，班主任会在三天内通过验证，请耐心等候</div>
                  <img class="qrcode" :src="groupInfo.master_qr" alt="" @click="clickImg(groupInfo.master_qr)">
              </div>
          </div>
      </div>
      <div class="safety-height">
          <div class="see-order" @click="orderLink">查看订单</div>
      </div>
  </div>
</div>
</template>

<script>
import Clipboard from 'clipboard'
import {ImagePreview} from "vant";
export default {
  name: "JoinClass",
  data(){
    return{
      groupInfo: {}
    }
  },
  created() {
    if(this.$route.params.id){
      this.getGroup(this.$route.params.id)
    }else {
      this.$router.replace({path: '/'})
    }
  },
  methods: {
     // 获取详情
     getGroup(id){
       let url = this.$api.OrdersQr + '/' + id;
       this.$http.get(url,true).then( res => {
        if(res.data.success){
          this.groupInfo = res.data.data;
        }
       })
     },
    // 点击复制事件
    copy(){
      var clipboard = new Clipboard('.student-num-btn');
      clipboard.on('success', e => {
        this.$toast('复制成功');
        // 释放内存
        clipboard.destroy()
      });
      clipboard.on('error', e => {
        // 不支持复制
        this.$toast('该浏览器不支持自动复制');
        // 释放内存
        clipboard.destroy()
      })
    },
      // 点击二维码
      clickImg(item){
          ImagePreview({
              images: [item],
          })
      },
    // 跳转兑换记录
    orderLink(){
       this.$router.push({path: '/user/record'})
    }
  }
}
</script>

<style scoped lang="scss">
$r: 750/16/1rem;
.course-pad-t{
    position: relative;
    .join-class-head{
        .join-bg{
            flex-direction: column;
            width: 750/$r;
            height: 303/$r;
            background: #FF6B29;
            .join-time{
                margin-top: 60/$r;
                margin-bottom: 18/$r;
                font-size: 30/$r;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 30/$r;
            }
            .join-tip{
                font-size: 24/$r;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 24/$r;
            }
        }
        .join-step-con{
            position: absolute;
            top: 172/$r;
            left: 0;
            right: 0;
            width: 680/$r;
            //height: 811/$r;
            padding: 6/$r 20/$r;
            box-sizing:border-box;
            background: #FFFFFF;
            border-radius: 10/$r;
            margin: 0 auto;
            .join-step{
                .stu-num-con{
                    display: flex;
                    margin-left: 50/$r;
                    .stu-num{
                        width: 334/$r;
                        height: 60/$r;
                        background: #FFE6DA;
                        border-radius: 4/$r;
                        font-size: 24/$r;
                        font-family: Source Han Sans CN;
                        font-weight: bold;
                        color: #FF6B29;
                        line-height: 60/$r;
                        text-align: center;
                    }
                    .stu-num-copy{
                        width: 154/$r;
                        height: 60/$r;
                        background: #FF6B29;
                        border-radius: 0/$r 4/$r 4/$r 0/$r;
                        font-size: 24/$r;
                        font-family: Source Han Sans CN;
                        font-weight: bold;
                        color: #FFFFFF;
                        line-height: 60/$r;
                        text-align: center;
                    }
                }
                .example{
                    display: flex;
                    margin: 50/$r 0 40/$r 50/$r;
                    .example-tit{
                        font-size: 24/$r;
                        font-family: Source Han Sans CN;
                        font-weight: bold;
                        color: #A6A6A6;
                        line-height: 36/$r;
                    }
                    .example-img{
                        width: 400/$r;
                        height: 155/$r;
                        background: #FFFFFF;
                        border: 1px solid #E5E5E5;
                        border-radius: 4/$r;
                    }
                }
            }
            .step-two{
                .stop-tip{
                    margin: 4/$r 0 45/$r;
                    font-size: 24/$r;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    color: #FF6B29;
                    line-height: 36/$r;
                }
                .qrcode{
                    display: block;
                    width: 200/$r;
                    height: 200/$r;
                    margin: 0 auto 54/$r;
                }
            }
            .step-title{
                display: flex;
                margin-top: 36/$r;
                margin-bottom: 26/$r;
                .step-num{
                    display: block;
                    width: 40/$r;
                    height: 40/$r;
                    margin-right: 9/$r;
                    background: #FF6B29;
                    border-radius: 50%;
                    font-size: 30/$r;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    color: #FFFFFF;
                    line-height: 40/$r;
                    text-align: center;
                }
                .step-text{
                    width: 491/$r;
                    height: 30/$r;
                    font-size: 30/$r;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    color: #4D4D4D;
                    line-height: 36/$r;
                }
            }
        }
    }
    .see-order{
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        height: 98/$r;
        background: #FF6B29;
        font-size: 28/$r;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 98/$r;
        text-align: center;
    }
}
</style>